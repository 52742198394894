import { types, Instance } from 'mobx-state-tree';
import moment from 'moment';

import Personal from './Personal';
import WorkBusinessInformation from './WorkBusinessInformation';
import InvestmentProfileInformation from './InvestmentProfileInformation';
import Disclosures from './Disclosures';
import FATCA from './FATCA';

const Accountholder = types
  .model({
    personal: Personal,
    work: WorkBusinessInformation,
    investment: InvestmentProfileInformation,
    disclosures: Disclosures,
    fatca: FATCA,
  })
  .actions(self => ({}));

export const createEmptyAccountholders = (count: number) => {
  const ach = Array(count)
    .fill(undefined)
    .map(() =>
      Accountholder.create({
        personal: Personal.create(),
        work: WorkBusinessInformation.create(),
        investment: InvestmentProfileInformation.create(),
        disclosures: Disclosures.create(),
        fatca: FATCA.create(),
      }),
    );
  return ach;
};

export interface AccountholderType extends Instance<typeof Accountholder> {} // eslint-disable-line

export default Accountholder;
