import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Row, Col } from 'antd';
import styled from 'styled-components';
import countriesList from 'country-list';
import { references } from '@project/common';

import { AccountholderType } from '../../types';

const countries = references.countries;
const nationalities = references.nationalities;

interface AccountholderProps {
  accountholder: AccountholderType;
  primary: boolean;
}

const Wrapper = styled.div`
  margin-bottom: 50px;

  .title {
    background: #9d050f;
    padding: 10px 10px;
    margin-bottom: 0px;
    color: white;
    font-weight: bold;
  }
  .section-title {
    background: #7f7f7f;
    padding: 10px 10px;
    color: white;
    font-weight: bold;
  }

  .content {
    padding: 5px 15px;
    margin-bottom: 20px;
  }

  .row {
    margin: 20px 0px;

    .label {
      margin-right: 10px;
      opacity: 0.65;
      font-weight: bold;
    }
    .value {
      min-width: 50px;
      font-weight: bold;
      display: inline-block;
    }
  }

  .d-block {
    display: block;
  }

  @media (max-width: 576px) {
    .row {
      margin: 0px;
      line-height: 2.5em;
    }
  }
`;

const Accountholder: React.FC<AccountholderProps> = ({ accountholder, primary }) => {
  return (
    <Wrapper>
      <p className="title">{primary ? 'PRIMARY' : 'SECONDARY'} ACCOUNTHOLDER</p>
      <div className="personal-section">
        <p className="section-title">PERSONAL INFORMATION</p>
        <div className="content">
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Last Name:</label>
              <span className="value">{accountholder.personal.lastName.toUpperCase()}</span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Suffix:</label>
              {accountholder.personal.suffix === 'others' ? (
                <span className="value">Others - {accountholder.personal.suffixOthers.toUpperCase()}</span>
              ) : (
                <span className="value">
                  {accountholder.personal.suffix && references.suffixes[accountholder.personal.suffix]}
                </span>
              )}
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">First Name:</label>
              <span className="value">{accountholder.personal.firstName.toUpperCase()}</span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Date of birth:</label>
              <span className="value">{accountholder.personal.dateOfBirth.toUpperCase()}</span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Middle Name:</label>
              <span className="value">{accountholder.personal.middleName.toUpperCase()}</span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Age:</label>
              <span className="value">{accountholder.personal.age}</span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={24}>
              <label className="label">Mother Maiden Name:</label>
              <span className="value">{accountholder.personal.motherMaidenName.toUpperCase()}</span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Nationality:</label>
              {accountholder.personal.nationality === 'others' ? (
                <span className="value">OTHERS - {accountholder.personal.nationalityOthers.toUpperCase()}</span>
              ) : (
                <span className="value">
                  {accountholder.personal.nationality && nationalities[accountholder.personal.nationality]}
                </span>
              )}
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Citizenship:</label>
              {accountholder.personal.citizenship === 'others' ? (
                <span className="value">OTHERS - {accountholder.personal.citizenshipOthers.toUpperCase()}</span>
              ) : (
                <span className="value">
                  {accountholder.personal.citizenship && countries[accountholder.personal.citizenship]}
                </span>
              )}
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Place of birth:</label>
              <span className="value">
                {accountholder.personal.birthCountry && countries[accountholder.personal.birthCountry].toUpperCase()},{' '}
                {accountholder.personal.birthCity.toUpperCase()}
              </span>
            </Col>
            <Col xs={24} md={6}>
              <label className="label">Civil Status:</label>
              <span className="value">
                {accountholder.personal.civilStatus &&
                  references.civilStatuses[accountholder.personal.civilStatus].toUpperCase()}
              </span>
            </Col>
            <Col xs={24} md={4}>
              <label className="label">Gender:</label>
              <span className="value">{accountholder.personal.gender?.toUpperCase()}</span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={24}>
              <label className="label">Spouse Name:</label>
              <span className="value">{accountholder.personal.spouseName.toUpperCase()}</span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Tax Identification Number:</label>
              <span className="value">{accountholder.personal.tin}</span>
            </Col>
            <Col xs={24} md={10} className="d-flex align-items-start">
              <label className="label">SSS / GSIS No:</label>
              <span className="value">
                <span className="d-block">
                  <Checkbox checked={accountholder.personal.sssGSISTag === 'sss'}>SSS</Checkbox>
                  {accountholder.personal.sssGSISTag === 'sss' ? (
                    <b className="ml-3 d-inline-block">{accountholder.personal.sssGSISNo}</b>
                  ) : null}
                </span>
                <span className="d-block">
                  <Checkbox checked={accountholder.personal.sssGSISTag === 'gsis'}>GSIS</Checkbox>
                  {accountholder.personal.sssGSISTag === 'gsis' ? (
                    <b className="ml-3 d-inline-block">{accountholder.personal.sssGSISNo}</b>
                  ) : null}
                </span>
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">First ID:</label>
              {accountholder.personal.idType1 ? (
                <span className="value">
                  {references.idTypes[accountholder.personal.idType1]} - {accountholder.personal.idNumber1}
                </span>
              ) : null}
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label d-block">Present Address:</label>
              <span className="value">
                {accountholder.personal.presentAddress1.toUpperCase()} <br />
                {accountholder.personal.presentAddress2.toUpperCase()} <br />
                {accountholder.personal.presentAddress3.toUpperCase()} <br />
                {accountholder.personal.presentAddressZipCode}
              </span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label d-block">Permanent Address</label>
              <span className="value">
                {accountholder.personal.permanentAddress1.toUpperCase()} <br />
                {accountholder.personal.permanentAddress2.toUpperCase()} <br />
                {accountholder.personal.permanentAddress3.toUpperCase()} <br />
                {accountholder.personal.permanentAddressZipCode}
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Home Phone Number:</label>
              <span className="value">{accountholder.personal.homePhoneNumber}</span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Mobile Number:</label>
              <span className="value">{accountholder.personal.mobileNumber}</span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Primary Email Address:</label>
              <span className="value">{accountholder.personal.primaryEmailAddress.toUpperCase()}</span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Secondary Email Address:</label>
              <span className="value">{accountholder.personal.secondaryEmailAddress.toUpperCase()}</span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="work-section">
        <p className="section-title">WORK / BUSINESS INFORMATION</p>
        <div className="content">
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Employment Status:</label>
              {accountholder.work.employmentStatus === 'others' ? (
                <span className="value">OTHERS - {accountholder.work.employmentStatusOthers?.toUpperCase()}</span>
              ) : (
                <span className="value">
                  {accountholder.work.employmentStatus &&
                    references.employmentStatuses[accountholder.work.employmentStatus].toUpperCase()}
                </span>
              )}
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Nature of Business:</label>
              {accountholder.work.natureOfBusiness === 'others' ? (
                <span className="value">OTHERS - {accountholder.work.natureOfBusinessOthers?.toUpperCase()}</span>
              ) : (
                <span className="value">
                  {accountholder.work.natureOfBusiness &&
                    references.natureOfBusiness[accountholder.work.natureOfBusiness].toUpperCase()}
                </span>
              )}
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Rank:</label>
              {accountholder.work.rank === 'others' ? (
                <span className="value"> OTHERS - {accountholder.work.rankOthers}</span>
              ) : (
                <span className="value">
                  {accountholder.work.rank && references.ranks[accountholder.work.rank].toUpperCase()}
                </span>
              )}
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Position:</label>
              <span className="value">{accountholder.work.position.toUpperCase()}</span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={24}>
              <label className="label">Name of Employer / Business:</label>
              <span className="value">{accountholder.work.nameOfEmployerOrBusiness.toUpperCase()}</span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label d-block">Office / Business Address:</label>
              <span className="value">
                {accountholder.work.businessAddress1.toUpperCase()} <br />
                {accountholder.work.businessAddress2.toUpperCase()} <br />
                {accountholder.work.businessAddress3.toUpperCase()} <br />
                {accountholder.work.businessAddressZipCode}
              </span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Office Phone Number:</label>
              <span className="value">{accountholder.work.officePhoneNumber}</span>
            </Col>
          </Row>
        </div>
      </div>

      <div className="work-section">
        <p className="section-title">INVESTMENT PROFILE & FINANCIAL INFORMATION</p>
        <div className="content">
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Investment Objective:</label>
              <span className="value">
                {accountholder.investment.investmentObjective &&
                  references.investmentObjectives[accountholder.investment.investmentObjective].toUpperCase()}
              </span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Years of Experience in Equity Investment:</label>
              <span className="value">
                {accountholder.investment.yearsOfExperienceInEquityInvestment &&
                  references.yearsReferences[
                    accountholder.investment.yearsOfExperienceInEquityInvestment
                  ].toUpperCase()}
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Do you have accounts with Brokers / Dealers:</label>
              {accountholder.investment.hasAccountsWithOtherBroker ? (
                <span className="value d-block ml-sm-5">
                  <span className="d-block">
                    <label className="label">Name of Broker / Dealer:</label>
                    <span className="value">{accountholder.investment.accountsWithOtherBrokerName.toUpperCase()}</span>
                  </span>
                </span>
              ) : null}
            </Col>
            <Col xs={24} md={10}>
              <label className="label"></label>
              <span className="value">
                <Checkbox checked={!accountholder.investment.hasAccountsWithOtherBroker}>NO</Checkbox>
                <Checkbox checked={accountholder.investment.hasAccountsWithOtherBroker}>YES</Checkbox>
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Source of Funds:</label>
              {accountholder.investment.sourceOfFunds === 'others' ? (
                <span className="value">OTHERS - {accountholder.investment.sourceOfFundsOthers.toUpperCase()}</span>
              ) : (
                <span className="value">
                  {accountholder.investment.sourceOfFunds &&
                    references.sourceOfFunds[accountholder.investment.sourceOfFunds]}
                </span>
              )}
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Annual Gross Income:</label>
              <span className="value">
                {accountholder.investment.annualGrossIncome &&
                  references.moneyReferences[accountholder.investment.annualGrossIncome].toUpperCase()}
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Assets:</label>
              <span className="value">
                {accountholder.investment.assets &&
                  references.moneyReferences[accountholder.investment.assets].toUpperCase()}
              </span>
            </Col>
            <Col xs={24} md={10}>
              <label className="label">Net Worth:</label>
              <span className="value">
                {accountholder.investment.netWorth &&
                  references.moneyReferences[accountholder.investment.netWorth].toUpperCase()}
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={24}>
              <label className="label">Investment Risk Appetite:</label>
              <span className="value">
                {accountholder.investment.investmentRiskAppetite &&
                  references.investmentRiskAppetite[accountholder.investment.investmentRiskAppetite].toUpperCase()}
              </span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="work-section">
        <p className="section-title">DISCLOSURES</p>
        <div className="content">
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label d-block">Are you an officer / director of a listed company?</label>
              {accountholder.disclosures.isOfficerOrDirectorOfListedCompany ? (
                <span className="value d-block ml-sm-5">
                  <span className="d-block">
                    <label className="label">Corporation:</label>
                    <span className="value">{accountholder.disclosures.corporationName.toUpperCase()}</span>
                  </span>
                  <span className="d-block">
                    <label className="label">Position:</label>
                    <span className="value">{accountholder.disclosures.position.toUpperCase()}</span>
                  </span>
                </span>
              ) : null}
            </Col>
            <Col xs={24} md={10}>
              <label className="label"></label>
              <span className="value">
                <Checkbox checked={!accountholder.disclosures.isOfficerOrDirectorOfListedCompany}>NO</Checkbox>
                <Checkbox checked={accountholder.disclosures.isOfficerOrDirectorOfListedCompany}>YES</Checkbox>
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label d-block">Are you an employee of a registered Broker / Dealer?</label>
              {accountholder.disclosures.isEmployeeOfRegisteredBroker ? (
                <span className="value d-block ml-sm-5">
                  <span className="d-block">
                    <label className="label">Name of Broker / Dealer:</label>
                    <span className="value">{accountholder.disclosures.employeeBrokerName.toUpperCase()}</span>
                  </span>
                </span>
              ) : null}
            </Col>
            <Col xs={24} md={10}>
              <label className="label"></label>
              <span className="value">
                <Checkbox checked={!accountholder.disclosures.isEmployeeOfRegisteredBroker}>NO</Checkbox>
                <Checkbox checked={accountholder.disclosures.isEmployeeOfRegisteredBroker}>YES</Checkbox>
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label d-block">Are you an officer / director of a registered Broker / Dealer?</label>
              {accountholder.disclosures.isOfficerOrDirectorOfARegisteredBroker ? (
                <span className="value d-block ml-sm-5">
                  <span className="d-block">
                    <label className="label">Name of Broker / Dealer:</label>
                    <span className="value">{accountholder.disclosures.officerBrokerName.toUpperCase()}</span>
                  </span>
                </span>
              ) : null}
            </Col>
            <Col xs={24} md={10}>
              <label className="label"></label>
              <span className="value">
                <Checkbox checked={!accountholder.disclosures.isOfficerOrDirectorOfARegisteredBroker}>NO</Checkbox>
                <Checkbox checked={accountholder.disclosures.isOfficerOrDirectorOfARegisteredBroker}>YES</Checkbox>
              </span>
            </Col>
          </Row>
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label d-block">Do you have any relatives working in BPI Securities?</label>
              {accountholder.disclosures.hasRelativesInBPISecurities ? (
                <span className="value d-block ml-sm-5">
                  <span className="d-block">
                    <label className="label">Name of Employee:</label>
                    <span className="value">{accountholder.disclosures.bpiSecuritiesEmployeeName.toUpperCase()}</span>
                  </span>
                </span>
              ) : null}
            </Col>
            <Col xs={24} md={10}>
              <label className="label"></label>
              <span className="value">
                <Checkbox checked={!accountholder.disclosures.hasRelativesInBPISecurities}>NO</Checkbox>
                <Checkbox checked={accountholder.disclosures.hasRelativesInBPISecurities}>YES</Checkbox>
              </span>
            </Col>
          </Row>
        </div>
      </div>
      <div className="fatca-section">
        <p className="section-title">FATCA</p>
        <div className="content">
          <Row className="row">
            <Col xs={24} md={14}>
              <label className="label">Are you a US Person?</label>
            </Col>
            <Col xs={24} md={4}>
              <label className="label"></label>
              <span className="value">
                <Checkbox checked={!accountholder.fatca.isUSPerson}>NO</Checkbox>
                <Checkbox checked={accountholder.fatca.isUSPerson}>YES</Checkbox>
              </span>
            </Col>
            {accountholder.fatca.isUSPerson ? (
              <Col xs={24} md={6}>
                <label className="label">US TIN:</label>
                <span className="value">{accountholder.fatca.tin}</span>
              </Col>
            ) : null}
          </Row>
        </div>
      </div>
    </Wrapper>
  );
};

Accountholder.propTypes = {
  accountholder: PropTypes.any.isRequired,
  primary: PropTypes.bool.isRequired,
};

export default Accountholder;
