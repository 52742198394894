import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';

import Steps from './Steps';
import { useMst } from '../store/RootStore';

const Wrapper = styled.div`
  background-color: #9c1e25;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 10;
`;

const InnerContainer = styled.div`
  height: 70px;
  width: 1024px;
  margin: 0px auto;
  display: flex;
  align-items: center;

  .logo {
    width: 150px;
    margin: 0px auto;
  }

  @media (max-width: 1024px) {
    width: auto;
    /* margin: 0px 30px; */
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 0px 20px;
  }
`;

const Header: React.FC = observer(() => {
  const { ui } = useMst();

  return (
    <Wrapper>
      <InnerContainer>
        <Link to="/">
          <img className="logo" src={'https://bpitrade.s3-ap-southeast-1.amazonaws.com/logo.jpg'} />
        </Link>
      </InnerContainer>
      {/* <Steps step={ui.step} /> */}
    </Wrapper>
  );
});

export default Header;
