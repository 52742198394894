import React from 'react';
import { observer } from 'mobx-react';
import countriesList from 'country-list';
import { Input, Radio, Checkbox, Select } from 'antd';
import PropTypes from 'prop-types';

import { AccountholderType } from '../../store/Accountholder';
import { FormSection, FormRow, RequiredFlag } from '../../components';

interface ComponentProps {
  errors: any;
  accountholder: AccountholderType;
  validateInput: (path: string) => void;
}

const FATCA: React.FC<ComponentProps> = observer(({ errors, accountholder, validateInput }) => {
  return (
    <FormSection id="fatca-section">
      <p className="title">FATCA</p>
      <span className="description">
        All fields with <RequiredFlag /> are mandatory
      </span>
      <FormRow>
        <label className="form-title">
          Are you a US Person? <RequiredFlag />
        </label>
        <span className="form-subtitle">
          A US Person includes a US Citizen or resident alien (e.g. US green cardholder) even if residing outside the US
        </span>
        <div className="controls --inline">
          <Radio.Group
            name="isUSPerson"
            value={accountholder.fatca.isUSPerson}
            onChange={e => accountholder.fatca.setIsUSPerson(e.target.value)}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {errors['fatca.isUSPerson'] ? <span className="error-message">{errors['fatca.isUSPerson']}</span> : null}
        <div className={errors['fatca.tin'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="tin"
            placeholder="US TIN"
            value={accountholder.fatca.tin}
            onChange={e => accountholder.fatca.setTin(e.target.value)}
            className="input"
            disabled={!accountholder.fatca.isUSPerson}
            onBlur={() => validateInput('fatca.tin')}
          />
          {accountholder.fatca.isUSPerson ? <RequiredFlag /> : null}
        </div>
        {errors['fatca.tin'] ? <span className="error-message">{errors['fatca.tin']}</span> : null}
      </FormRow>

      <FormRow>
        <Checkbox
          name="correctInfoAgreement"
          checked={accountholder.fatca.correctInfoAgreement}
          onChange={e => accountholder.fatca.setCorrectInfoAgreement(e.target.checked)}
        >
          <span style={{ fontSize: 11 }}>
            I certify that all information provided herein are true, accurate and complete, and I agree to promptly
            inform BPI Securities Corporation (“BPI Sec”) of any changes thereto. I authorize BPI Sec to rely upon my
            declaration herein and, if I am a US Person or have US indicators that render my account reportable under
            FATCA, I consent to the reporting and disclosure of the required information by the BPI Sec to the Internal
            Revenue Services (IRS) and/or Bureau of Internal Revenue (BIR) in compliance with FATCA. In consideration of
            the foregoing, I agree to hold BPI Sec, its directors, officers, employees, representatives and agents free
            and harmless from any liability, action and suits, costs, and expenses, arising from or in connection with
            the BPI Sec’s compliance with FATCA regulations and/or as a result of disclosure made to the US IRS and/or
            BIR.
          </span>
        </Checkbox>
        {errors['fatca.correctInfoAgreement'] ? (
          <span className="error-message">You must agree to continue</span>
        ) : null}
      </FormRow>
      <FormRow>
        <Checkbox
          name="disclosuresAgreement"
          checked={accountholder.fatca.disclosuresAgreement}
          onChange={e => accountholder.fatca.setDisclosuresAgreement(e.target.checked)}
        >
          <span style={{ fontSize: 11 }}>
            I/WE likewise agree that this constitutes my/our consent to the disclosure of account information under the
            Bank Secrecy Laws including R.A. No. 1405 (Law on Secrecy of Bank Deposits), R.A. No. 6426 (Foreign Currency
            Deposit Act), R.A. No. 8791 (General Banking Law of 2000) and R.A. No. 10173 (Data Privacy Act of 2012) and
            other similar and related laws.
          </span>
        </Checkbox>
        {errors['fatca.disclosuresAgreement'] ? (
          <span className="error-message">You must agree to continue</span>
        ) : null}
      </FormRow>
    </FormSection>
  );
});

FATCA.propTypes = {
  errors: PropTypes.any.isRequired,
  accountholder: PropTypes.any.isRequired,
  validateInput: PropTypes.func.isRequired,
};

export default FATCA;
