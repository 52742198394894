import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.span`
  display: inline-block;

  font-size: 16px;
  font-weight: bold;
  margin: 5px;
  color: #9c1e25;
  max-width: 80px;
`;

export const RequiredFlag: React.FC = () => <Wrapper>*</Wrapper>;
