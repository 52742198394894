import { types } from 'mobx-state-tree';

const InvestmentProfileInformation = types
  .model({
    investmentObjective: types.maybe(types.string),
    yearsOfExperienceInEquityInvestment: types.maybe(types.string),
    hasAccountsWithOtherBroker: types.maybe(types.boolean),
    accountsWithOtherBrokerName: '',
    sourceOfFunds: types.maybe(types.string),
    sourceOfFundsOthers: '',
    annualGrossIncome: types.maybe(types.string),
    assets: types.maybe(types.string),
    netWorth: types.maybe(types.string),
    investmentRiskAppetite: types.maybe(types.string),
  })
  .actions(self => ({
    setInvestmentObjective(v: string): void {
      self.investmentObjective = v;
    },
    setYearsOfExperienceInEquityInvestment(v: string): void {
      self.yearsOfExperienceInEquityInvestment = v;
    },
    setHasAccountWithOtherBroker(v: boolean): void {
      self.hasAccountsWithOtherBroker = v;
      if (!v) {
        self.accountsWithOtherBrokerName = '';
      }
    },
    setAccountWithOtherBrokerName(v: string): void {
      self.accountsWithOtherBrokerName = v;
    },
    setSourceOfFunds(v: string): void {
      self.sourceOfFunds = v;

      if (self.sourceOfFunds != 'others') {
        self.sourceOfFundsOthers = '';
      }
    },
    setSourceOfFundsOthers(v: string): void {
      self.sourceOfFundsOthers = v;
    },
    setAnnualGrossIncome(v: string): void {
      self.annualGrossIncome = v;
    },
    setAssets(v: string): void {
      self.assets = v;
    },
    setNetWorth(v: string): void {
      self.netWorth = v;
    },
    setInvestmentRiskAppetite(v: string): void {
      self.investmentRiskAppetite = v;
    },
  }));

export default InvestmentProfileInformation;
