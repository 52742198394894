import { TradeAccountDetailsType } from './../types';
import { types } from 'mobx-state-tree';

const TradeAccountDetails = types
  .model({
    bpiTradeUserId: '',
    password: '',
    confirmPassword: '',
    securityQuestion: types.maybe(types.string),
    securityQuestionAnswer: '',
    bankAccountName: '',
    bankAccountNumber: '',
    hasAnExistingBPITradeAccount: false,
    accountCode: '',
    mailingMethod: '',
    preferredMailingAddress: '',
  })
  .actions(self => ({
    setBpiTradeUserId(v: string) {
      self.bpiTradeUserId = v;
    },
    setPassword(v: string) {
      self.password = v;
    },
    setConfirmPassword(v: string) {
      self.confirmPassword = v;
    },
    setSecurityQuestion(v: string) {
      self.securityQuestion = v;
    },
    setSecurityQuestionAnswer(v: string) {
      self.securityQuestionAnswer = v;
    },
    setBankAccountName(v: string) {
      self.bankAccountName = v;
    },
    setBankAccountNumber(v: string) {
      self.bankAccountNumber = v;
    },
    setHasAnExistingBPITradeAccount(v: boolean) {
      self.hasAnExistingBPITradeAccount = v;
    },
    setAccountCode(v: string) {
      self.accountCode = v;
    },
    setMailingMethod(v: string) {
      self.mailingMethod = v;
    },
    setPreferredMailingAddress(v: string) {
      self.preferredMailingAddress = v;
    },
  }));

export default TradeAccountDetails;
