import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Modal } from 'antd';
import { RouteProps } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useMst } from '../store/RootStore';

const Wrapper = styled.div`
  height: calc(100vh - 230px);
  margin-top: 140px;
  margin: 0px auto;

  .emphasis {
    font-weight: bold;
    /* color: #9c1e25; */
  }

  .btn-login {
    width: 430px;
    height: 70px;
    margin-top: 30px;
    font-size: 18px;
    font-weight: bold;

    &.neutral {
      color: #888;
      border: 1px solid #aaa;
      background: white;

      &:hover {
        background: #eee;
      }
    }
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal-info {
    .title {
      font-size: 24px;
      font-weight: bold;
    }
    .content {
      font-size: 20px;
    }
  }

  @media (max-width: 768px) {
    .btn-login {
      font-size: 16px;
      width: 100%;
    }
  }

  @media (max-width: 320px) {
    .btn-login {
      font-size: 12px;
      width: 100%;
    }
  }
`;

const styles = {
  modal: {
    wrapper: {
      padding: '40px 30px',
    },
    title: {
      fontSize: '24px',
      marginBottom: 20,
    },
    detail: {
      fontSize: '18px',
      marginBottom: 20,
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 60,
    } as React.CSSProperties,
    okButton: {
      padding: '10px 15px',
      height: 40,
      fontWeight: 700,
      marginBottom: 20,
    } as React.CSSProperties,
    cancelButton: {
      padding: '10px 15px',
      height: 40,
      fontWeight: 700,
    } as React.CSSProperties,
  },
};

const Login: React.FC<RouteProps> = observer(() => {
  const store = useMst();
  const [loading, setLoading] = useState(false);
  const [modalDontHaveBankAccountVisible, setModalDontHaveBankAccountVisible] = useState(false);
  const [modalHaveBankAccountVisible, setModalHaveBankAccountVisible] = useState(false);

  const handleLogin = async () => {
    setLoading(true);
    const response = await store.login();
    window.location.href = response.data.url;
    // setLoading(false);
  };

  const handleHaveAccount = () => {
    setModalHaveBankAccountVisible(true);
  };

  const handleDontHaveAccount = () => {
    setModalDontHaveBankAccountVisible(true);
  };
  return (
    <Wrapper>
      {/* <p className="heading-1">Sign-up for a BPI Trade account in 3 simple steps</p>
      <p className="heading-4">
        1. Click <span className="emphasis">I have a BPI account button</span> then login using your bpiexpressonline
        account and review agreement.
      </p>
      <p className="heading-4">2. Create your BPI Trade account and tell us your investment profile</p>
      <p className="heading-4">3. Review your details and wait for email confirmation.</p> */}
      <div className="buttons mb-3">
        <Button onClick={handleHaveAccount} className="btn-primary btn-login" type="primary">
          I have a BPI/BPI Family bank account
        </Button>
        <Button onClick={handleDontHaveAccount} className="btn-primary btn-login">
          {"I don't have a BPI/BPI Family bank account"}
        </Button>
      </div>
      <Modal
        footer={null}
        centered
        visible={modalHaveBankAccountVisible}
        onCancel={() => setModalHaveBankAccountVisible(false)}
      >
        <div style={styles.modal.wrapper}>
          <p style={styles.modal.detail}>
            We are about to collect your BPI online username and password on the next page. <br /> <br />
            {'If you wish to continue, click "Proceed", Otherwise, kindly exit the BPI Trade Application page.'}
          </p>
          <div style={styles.modal.buttons}>
            <Button
              loading={loading}
              onClick={handleLogin}
              style={styles.modal.okButton}
              className="btn-primary"
              type="primary"
            >
              Proceed
            </Button>
            <Button
              disabled={loading}
              style={styles.modal.cancelButton}
              onClick={() => {
                if (window) {
                  window.open(window.location.toString(), '_self')?.close();
                }
              }}
              type="default"
            >
              Exit application
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        footer={null}
        centered
        visible={modalDontHaveBankAccountVisible}
        onCancel={() => setModalDontHaveBankAccountVisible(false)}
      >
        <div style={styles.modal.wrapper}>
          <h4 style={styles.modal.title}>{"Don't have a BPI / BPI Famiy bank account yet?"}</h4>
          <p style={styles.modal.detail}>
            A BPI or BPI Family bank account is required for you to open a BPI Trade account. Head over to the BPI / BPI
            Family branch nearest you to open an account
          </p>
          <div style={styles.modal.buttons}>
            <Button onClick={() => window.close()} style={styles.modal.okButton} className="btn-primary" type="primary">
              Exit BPI Trade application
            </Button>
            <Button
              style={styles.modal.cancelButton}
              onClick={() => setModalDontHaveBankAccountVisible(false)}
              type="default"
            >
              Cancel
            </Button>
          </div>
        </div>
      </Modal>
    </Wrapper>
  );
});

export default Login;
