import { types } from 'mobx-state-tree';

const UI = types
  .model('UI', {
    step: 1,
  })
  .actions(self => ({
    setStep(value: number) {
      self.step = value;
    },
  }));

export default UI;
