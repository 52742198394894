import React from 'react';
import PropTypes from 'prop-types';

import { AccountholderHeader } from './index.style';
import { observer } from 'mobx-react';

interface ComponentProps {
  count: number;
  current: number;
  onStepClicked?: (index: number) => void;
}

const Component: React.FC<ComponentProps> = observer(({ count, current, onStepClicked }) => {
  const steps = [];

  for (let index = 0; index < count; index++) {
    steps.push(
      <div key={`step-${index}`} className={`step ${index === current ? 'active' : ''}`}>
        <a onClick={e => onStepClicked && onStepClicked(index)} className="counter">
          {index + 1}
        </a>
      </div>,
    );

    if (count > 1 || index != count - 1) {
      steps.push(
        <span key={`divider-${index}`} className="divider">
          {' '}
          /{' '}
        </span>,
      );
    }
  }
  return (
    <AccountholderHeader>
      <div className="wrapper">
        <span className="title">
          {current === 0 && count > 1 ? 'Primary' : count === 1 ? 'Primary' : 'Secondary'} Accountholder
        </span>
        <div className="steps">{steps}</div>
      </div>
    </AccountholderHeader>
  );
});

Component.propTypes = {
  count: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  onStepClicked: PropTypes.func.isRequired,
};

export default Component;
