import styled from 'styled-components';

export const FormSection = styled.section`
  padding-top: 185px;
  margin-top: -180px;

  .title {
    font-size: 24px;
    /* font-weight: bold; */
    margin-bottom: 5px;
  }

  .description {
    display: block;
    margin-bottom: 30px;
    font-size: 12px;
  }
`;
