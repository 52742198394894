import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Icon, Button, notification } from 'antd';
import { RouteProps, useHistory, useLocation } from 'react-router-dom';
import { getSnapshot } from 'mobx-state-tree';
import { observer } from 'mobx-react';
import { useBeforeunload } from 'react-beforeunload';

import { schema, helpers } from '@project/common';

import { useMst } from '../../store/RootStore';
import { FormSection } from '../../components';

import { Wrapper, Main } from './index.style';
import Sidebar from './Sidebar';
import AccountholderHeader from './AccountholderHeader';
import PersonalInformation from './PersonalInformation';
import WorkBusinessInformation from './WorkBusinessInformation';
import InvestmentProfileInformation from './InvestmentProfileInformation';
import Disclosures from './Disclosures';
import FATCA from './FATCA';

const AccountholderDetails: React.FC<RouteProps> = observer(() => {
  const store = useMst();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const { ui, accountholders, applicationDetails } = useMst();
  useEffect(() => {
    ui.setStep(2);
  }, []);

  const [errors, setErrors] = useState<any>({});
  const [currentAccountholderIndex, setCurrentAccountholderIndex] = useState(0);
  const accountholder = accountholders[currentAccountholderIndex];

  const validateInput = async (path: string): Promise<void> => {
    try {
      const data = getSnapshot(accountholder) as yup.InferType<typeof schema.accountholderSchema>;
      const context = { ownership: applicationDetails.ownership, currentAccountholderIndex: currentAccountholderIndex };
      await schema.accountholderSchema.validateAt(path, data, { context: context });
      const c = { ...errors };
      delete c[path];
      setErrors(c);
    } catch (e) {
      const c = { ...errors, [path]: e.message };
      setErrors(c);
    }
  };

  const validateCurrentAccountholder = async (): Promise<boolean> => {
    try {
      const context = { ownership: applicationDetails.ownership, currentAccountholderIndex: currentAccountholderIndex };
      const result = await schema.accountholderSchema.validate(accountholder, { abortEarly: false, context });
      return true;
    } catch (e) {
      setErrors(helpers.formatErrorMessages(e));
      notification.open({
        message: 'Incomplete fields',
        description: 'Please check the form again and complete the fields before you submit the details',
        icon: <Icon type="frown" theme="outlined" style={{ color: '##9c1e25' }} />,
      });
      return false;
    }
  };

  const handlePrevious = (): void => {
    setCurrentAccountholderIndex(currentAccountholderIndex - 1);
  };
  const handleNext = async (): Promise<void> => {
    const ok = await validateCurrentAccountholder();
    if (ok) {
      setErrors({});
      setCurrentAccountholderIndex(currentAccountholderIndex + 1);
    }
    window.scrollTo(0, 0);
  };
  const handleSubmit = async (): Promise<void> => {
    const ok = await validateCurrentAccountholder();
    if (ok) {
      history.push('/review_accountholders');
      window.scrollTo(0, 0);
      await store.logMessage();
    } else {
      window.scrollTo(0, 0);
    }
  };

  useBeforeunload(() => 'Are you sure you want to leave the page?');

  console.log(errors);

  return (
    <Wrapper>
      <Sidebar />
      <Main>
        <AccountholderHeader count={accountholders.length} current={currentAccountholderIndex} />
        <div className="form-wrapper form">
          <PersonalInformation
            currentAccountholderIndex={currentAccountholderIndex}
            accountholder={accountholder}
            errors={errors}
            validateInput={validateInput}
          />
          <WorkBusinessInformation accountholder={accountholder} errors={errors} validateInput={validateInput} />
          <InvestmentProfileInformation accountholder={accountholder} errors={errors} validateInput={validateInput} />
          <Disclosures accountholder={accountholder} errors={errors} validateInput={validateInput} />
          <FATCA accountholder={accountholder} errors={errors} validateInput={validateInput} />

          <FormSection className="form-buttons">
            {currentAccountholderIndex > 0 && accountholders.length > 1 ? (
              <Button onClick={handlePrevious} type="default" size="large" style={{ marginRight: 10 }}>
                <Icon type="caret-left" theme="filled" />
                Previous
              </Button>
            ) : null}
            {currentAccountholderIndex < accountholders.length - 1 ? (
              <Button onClick={handleNext} type="primary" size="large" style={{ marginRight: 10 }}>
                Next
                <Icon type="caret-right" theme="filled" />
              </Button>
            ) : null}
            {currentAccountholderIndex === accountholders.length - 1 ? (
              <Button onClick={handleSubmit} type="primary" size="large" htmlType="submit">
                Submit and Review
              </Button>
            ) : null}
          </FormSection>
          {currentAccountholderIndex === accountholders.length - 1 ? (
            <FormSection className="form-buttons">
              <hr style={{ opacity: 0.3 }} />
              <Button size="large" onClick={() => window.location.replace('/')} type="default" className="mr-2">
                Exit application
              </Button>
            </FormSection>
          ) : null}
        </div>
      </Main>
    </Wrapper>
  );
});

export default AccountholderDetails;
