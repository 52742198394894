import styled from 'styled-components';

export const FormRow = styled.div<{ marginBottom?: string }>`
  margin-bottom: ${props => props.marginBottom || '20px'};

  .form-title {
    display: block;
    font-weight: bold;
    margin-bottom: 8px;
  }
  .form-subtitle {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .controls {
    display: flex;
    flex-direction: column;
    > * {
      margin-bottom: 10px;
      flex-grow: 1;
    }

    &.row,
    &.--inline {
      flex-direction: row;
      align-items: center;
    }
  }
`;
