import { types } from 'mobx-state-tree';

const FATCA = types
  .model({
    isUSPerson: types.maybe(types.boolean),
    tin: '',
    correctInfoAgreement: false,
    disclosuresAgreement: false,
  })
  .actions(self => ({
    setIsUSPerson(v: boolean): void {
      self.isUSPerson = v;
      if (!v) {
        self.tin = '';
      }
    },
    setTin(v: string): void {
      self.tin = v;
    },
    setCorrectInfoAgreement(v: boolean): void {
      self.correctInfoAgreement = v;
    },
    setDisclosuresAgreement(v: boolean): void {
      self.disclosuresAgreement = v;
    },
  }));

export default FATCA;
