import React from 'react';
import { observer } from 'mobx-react';
import countriesList from 'country-list';
import { Input, Radio, Checkbox, Select } from 'antd';
import PropTypes from 'prop-types';

import { AccountholderType } from '../../store/Accountholder';
import { FormSection, FormRow, RequiredFlag } from '../../components';

interface ComponentProps {
  errors: any;
  accountholder: AccountholderType;
  validateInput: (path: string) => void;
}

const Disclosures: React.FC<ComponentProps> = observer(({ errors, accountholder, validateInput }) => {
  return (
    <FormSection id="disclosures-section">
      <p className="title">Disclosures</p>
      <span className="description">
        All fields with <RequiredFlag /> are mandatory
      </span>
      <FormRow>
        <label className="form-title">Are you an officer / director of a listed company</label>
        <div className="controls --inline">
          <Radio.Group
            name="isOfficerOrDirectorOfListedCompany"
            value={accountholder.disclosures.isOfficerOrDirectorOfListedCompany}
            onChange={e => {
              accountholder.disclosures.setIsOfficerOrDirectorOfListedCompany(e.target.value);
              validateInput('disclosures.isOfficerOrDirectorOfListedCompany');
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {errors['disclosures.isOfficerOrDirectorOfListedCompany'] ? (
          <span className="error-message">{errors['disclosures.isOfficerOrDirectorOfListedCompany']}</span>
        ) : null}
        <div className={errors['disclosures.corporationName'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="corporationName"
            placeholder="Corporation name"
            value={accountholder.disclosures.corporationName}
            onChange={e => accountholder.disclosures.setCorporationname(e.target.value)}
            className="input"
            disabled={!accountholder.disclosures.isOfficerOrDirectorOfListedCompany}
            onBlur={() => validateInput('disclosures.corporationName')}
          />
          {accountholder.disclosures.isOfficerOrDirectorOfListedCompany ? <RequiredFlag /> : null}
        </div>
        {errors['disclosures.corporationName'] ? (
          <span className="error-message">{errors['disclosures.corporationName']}</span>
        ) : null}

        <div className={errors['disclosures.position'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="position"
            placeholder="Position"
            value={accountholder.disclosures.position}
            onChange={e => accountholder.disclosures.setPosition(e.target.value)}
            className="input"
            disabled={!accountholder.disclosures.isOfficerOrDirectorOfListedCompany}
            onBlur={() => validateInput('disclosures.position')}
          />
          {accountholder.disclosures.isOfficerOrDirectorOfListedCompany ? <RequiredFlag /> : null}
        </div>
        {errors['disclosures.position'] ? (
          <span className="error-message">{errors['disclosures.position']}</span>
        ) : null}
      </FormRow>

      <FormRow>
        <label className="form-title">Are you an employee of a registered Broker/Dealer?</label>
        <div
          className={
            errors['disclosures.isEmployeeOfRegisteredBroker'] ? 'controls --inline has-error' : 'controls --inline'
          }
        >
          <Radio.Group
            name="isEmployeeOfRegisteredBroker"
            value={accountholder.disclosures.isEmployeeOfRegisteredBroker}
            onChange={e => {
              accountholder.disclosures.setIsEmployeeOfRegisteredBroker(e.target.value);
              validateInput('disclosures.isEmployeeOfRegisteredBroker');
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {errors['disclosures.isEmployeeOfRegisteredBroker'] ? (
          <span className="error-message">{errors['disclosures.isEmployeeOfRegisteredBroker']}</span>
        ) : null}

        <div className={errors['disclosures.employeeBrokerName'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="employeeBrokerName"
            placeholder="Name of broker"
            value={accountholder.disclosures.employeeBrokerName}
            onChange={e => accountholder.disclosures.setEmployeeBrokerName(e.target.value)}
            className="input"
            disabled={!accountholder.disclosures.isEmployeeOfRegisteredBroker}
            onBlur={() => validateInput('disclosures.employeeBrokerName')}
          />
          {accountholder.disclosures.isEmployeeOfRegisteredBroker ? <RequiredFlag /> : null}
        </div>
        {errors['disclosures.employeeBrokerName'] ? (
          <span className="error-message">{errors['disclosures.employeeBrokerName']}</span>
        ) : null}
      </FormRow>

      <FormRow>
        <label className="form-title">Are you an officer / director of a registered Broker/Dealer?</label>
        <div
          className={
            errors['disclosures.isOfficerOrDirectorOfARegisteredBroker']
              ? 'controls --inline has-error'
              : 'controls --inline'
          }
        >
          <Radio.Group
            name="isOfficerOrDirectorOfARegisteredBroker"
            value={accountholder.disclosures.isOfficerOrDirectorOfARegisteredBroker}
            onChange={e => {
              accountholder.disclosures.setIsOfficerOrDirectorOfARegisteredBroker(e.target.value);
              validateInput('disclosures.isOfficerOrDirectorOfARegisteredBroker');
            }}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {errors['disclosures.isOfficerOrDirectorOfARegisteredBroker'] ? (
          <span className="error-message">{errors['disclosures.isOfficerOrDirectorOfARegisteredBroker']}</span>
        ) : null}
        <div className={errors['disclosures.officerBrokerName'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="officerBrokerName"
            placeholder="Name of broker"
            value={accountholder.disclosures.officerBrokerName}
            onChange={e => accountholder.disclosures.setOfficerBrokerName(e.target.value)}
            className="input"
            disabled={!accountholder.disclosures.isOfficerOrDirectorOfARegisteredBroker}
            onBlur={() => validateInput('disclosures.officerBrokerName')}
          />
          {accountholder.disclosures.isOfficerOrDirectorOfARegisteredBroker ? <RequiredFlag /> : null}
        </div>
        {errors['disclosures.officerBrokerName'] ? (
          <span className="error-message">{errors['disclosures.officerBrokerName']}</span>
        ) : null}
      </FormRow>

      <FormRow>
        <label className="form-title">Do you have any relatives working in BPI Securities?</label>
        <div className="controls --inline">
          <Radio.Group
            name="hasRelativesInBPISecurities"
            value={accountholder.disclosures.hasRelativesInBPISecurities}
            onChange={e => accountholder.disclosures.setHasRelativesInBPISecurities(e.target.value)}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {errors['disclosures.hasRelativesInBPISecurities'] ? (
          <span className="error-message">{errors['disclosures.hasRelativesInBPISecurities']}</span>
        ) : null}
        <div
          className={
            errors['disclosures.bpiSecuritiesEmployeeName'] ? 'controls --inline has-error' : 'controls --inline'
          }
        >
          <Input
            name="bpiSecuritiesEmployeeName"
            placeholder="Employee name"
            value={accountholder.disclosures.bpiSecuritiesEmployeeName}
            onChange={e => accountholder.disclosures.setBPISecuritiesEmployeeName(e.target.value)}
            className="input"
            disabled={!accountholder.disclosures.hasRelativesInBPISecurities}
            onBlur={() => validateInput('disclosures.bpiSecuritiesEmployeeName')}
          />
          {accountholder.disclosures.hasRelativesInBPISecurities ? <RequiredFlag /> : null}
        </div>
        {errors['disclosures.bpiSecuritiesEmployeeName'] ? (
          <span className="error-message">{errors['disclosures.bpiSecuritiesEmployeeName']}</span>
        ) : null}
      </FormRow>
    </FormSection>
  );
});

Disclosures.propTypes = {
  errors: PropTypes.any.isRequired,
  accountholder: PropTypes.any.isRequired,
  validateInput: PropTypes.func.isRequired,
};

export default Disclosures;
