import { types } from 'mobx-state-tree';
import moment from 'moment';

const Personal = types
  .model({
    firstName: '',
    middleName: '',
    lastName: '',
    suffix: types.maybe(types.string),
    suffixOthers: '',
    dateOfBirth: '',
    gender: types.maybe(types.string),
    birthCountry: types.maybe(types.string),
    birthCity: '',
    nationality: types.maybe(types.string),
    nationalityOthers: '',
    citizenship: types.maybe(types.string),
    citizenshipOthers: '',
    civilStatus: types.maybe(types.string),
    spouseName: '',
    motherMaidenName: '',
    tin: '',
    sssGSISTag: types.maybe(types.string),
    sssGSISNo: '',
    idType1: types.maybe(types.string),
    idNumber1: '',
    idType2: types.maybe(types.string),
    idNumber2: '',
    presentAddress1: '',
    presentAddress2: '',
    presentAddress3: '',
    presentAddressZipCode: '',
    permanentAddressCheck: false,
    permanentAddress1: '',
    permanentAddress2: '',
    permanentAddress3: '',
    permanentAddressZipCode: '',
    homePhoneNumber: '',
    mobileNumber: '',
    primaryEmailAddress: '',
    secondaryEmailAddress: '',
  })
  .actions(self => ({
    setFirstName(v: string): void {
      self.firstName = v;
    },
    setMiddleName(v: string): void {
      self.middleName = v;
    },
    setLastName(v: string): void {
      self.lastName = v;
    },
    setSuffix(v: string): void {
      self.suffix = v;
      if (v !== 'others') {
        self.suffixOthers = '';
      }
    },
    setSuffixOthers(v: string): void {
      self.suffixOthers = v;
    },
    setDateOfBirth(v: string): void {
      self.dateOfBirth = v;
    },
    setGender(v: string): void {
      self.gender = v;
    },
    setBirthCountry(v: string): void {
      self.birthCountry = v;
    },
    setBirthCity(v: string): void {
      self.birthCity = v;
    },
    setNationality(v: string): void {
      self.nationality = v;
      if (self.nationality != 'others') {
        self.nationalityOthers = '';
      }
    },
    setNationalityOthers(v: string): void {
      self.nationalityOthers = v;
    },
    setCitizenship(v: string): void {
      self.citizenship = v;
      if (self.citizenship != 'others') {
        self.citizenshipOthers = '';
      }
    },
    setCitizenshipOthers(v: string): void {
      self.citizenshipOthers = v;
    },
    setCivilStatus(v: string): void {
      self.civilStatus = v;
    },
    setSpouseName(v: string): void {
      self.spouseName = v;
    },
    setMotherMaidenName(v: string): void {
      self.motherMaidenName = v;
    },
    setTin(v: string): void {
      self.tin = v;
    },
    setSSSGSISTag(v: string): void {
      self.sssGSISTag = v;
    },
    setSSSGSISNo(v: string): void {
      self.sssGSISNo = v;
    },
    setIdType1(v: string): void {
      self.idType1 = v;
    },
    setIdNumber1(v: string): void {
      self.idNumber1 = v;
    },
    setIdType2(v: string): void {
      self.idType2 = v;
    },
    setIdNumber2(v: string): void {
      self.idNumber2 = v;
    },
    setPresentAddress1(v: string): void {
      self.presentAddress1 = v;
      if (self.permanentAddressCheck) {
        self.permanentAddress1 = v;
      }
    },
    setPresentAddress2(v: string): void {
      self.presentAddress2 = v;
      if (self.permanentAddressCheck) {
        self.permanentAddress2 = v;
      }
    },
    setPresentAddress3(v: string): void {
      self.presentAddress3 = v;
      if (self.permanentAddressCheck) {
        self.permanentAddress3 = v;
      }
    },
    setPresentAddressZipCode(v: string): void {
      self.presentAddressZipCode = v;
      if (self.permanentAddressCheck) {
        self.permanentAddressZipCode = v;
      }
    },
    setPermanentAddressCheck(v: boolean): void {
      self.permanentAddressCheck = v;

      if (v) {
        self.permanentAddress1 = self.presentAddress1;
        self.permanentAddress2 = self.presentAddress2;
        self.permanentAddress3 = self.presentAddress3;
        self.permanentAddressZipCode = self.presentAddressZipCode;
      }
    },
    setPermanentAddress1(v: string): void {
      self.permanentAddress1 = v;
    },
    setPermanentAddress2(v: string): void {
      self.permanentAddress2 = v;
    },
    setPermanentAddress3(v: string): void {
      self.permanentAddress3 = v;
    },
    setPermanentAddressZipCode(v: string): void {
      self.permanentAddressZipCode = v;
    },
    setHomePhoneNumber(v: string): void {
      self.homePhoneNumber = v;
    },
    setMobileNumber(v: string): void {
      self.mobileNumber = v;
    },
    setPrimaryEmailAddress(v: string): void {
      self.primaryEmailAddress = v;
    },
    setSecondaryEmailAddress(v: string): void {
      self.secondaryEmailAddress = v;
    },
  }))
  .views(self => ({
    get dateOfBirthMoment() {
      if (self.dateOfBirth.length > 0) {
        return moment(self.dateOfBirth, 'YYYY-MM-DD');
      }
      return null;
    },
    get age() {
      if (self.dateOfBirth.length > 0) {
        return moment().diff(moment(self.dateOfBirth), 'years');
      }
      return null;
    },
  }));

export default Personal;
