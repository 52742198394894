import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 170px;

  .btn-submit {
    margin-top: 30px;
    font-weight: bold;
  }

  .input {
    color: #333;

    &::placeholder {
      color: #888;
    }
  }

  .input-sm {
    width: 80px;
  }

  .form-buttons {
    /* position: fixed;
    background: white;
    bottom: 0;
    height: 20px; */
  }
`;

export const Sidebar = styled.div`
  width: 180px;
  margin-right: 40px;
  font-size: 14px;
  top: 180px;
  position: fixed;

  .scroll-wrapper {
    border-left: 1px solid #ccc;
  }

  .link {
    display: block;
    margin-bottom: 15px;
    padding-left: 20px;
    color: #4a4a4a;

    &.active {
      font-weight: bold;
      border-left: 2px solid #9c1e25;
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Main = styled.div`
  margin-left: 220px;
  margin-bottom: 500px;

  .form {
    width: 350px;
  }

  .ant-select,
  .ant-calendar-picker {
    width: 100%;
  }

  @media (max-width: 768px) {
    margin-left: 0px;
    margin-bottom: 0px;
    margin: 0px auto;

    .form {
      width: auto;
    }
  }
`;

export const AccountholderHeader = styled.div`
  background: #400c0f;
  height: 45px;
  position: fixed;
  left: 0;
  width: 100%;
  color: white;
  top: 70px;
  z-index: 20;
  display: flex;

  .wrapper {
    width: 1024px;
    margin: 0px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .title {
    color: white;
    margin-left: 220px;
    font-size: 14px;
    display: inline-block;
    margin-right: 40px;
    font-weight: bold;
  }

  .steps {
    display: flex;
    align-items: center;
    color: white;
    list-style: none;
    padding: 0;

    .step {
      padding: 0px;
      opacity: 0.5;
      /* cursor: pointer; */

      &.active {
        font-weight: bold;
        opacity: 1;
        border-bottom: 2px solid white;
      }

      &:hover {
        /* opacity: 0.8; */
        /* border-bottom: 2px solid #eee; */
      }
    }

    .divider {
      margin: 0px 8px;
    }

    .counter {
      color: #fff;
      padding: 1px 4px;
      font-size: 14px;
    }
  }
  @media (max-width: 768px) {
    top: 70px;
  }

  @media (max-width: 630px) {
    .title {
      margin-left: 0px;
      padding: 0px 20px;
    }
  }
`;
