import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  bottom: 0;
  background: white;
  padding: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #6b1519;
  color: white;

  .inner-container {
    width: 1024px;
    font-size: 9px;
    opacity: 0.7;
  }

  @media (max-width: 768px) {
    margin-top: 70px;
    flex-direction: column;
    position: relative;
    width: auto;

    .inner-container {
      width: auto;
      padding: 0px 20px;
    }
  }
`;

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <div className="inner-container">
        DISCLAIMER: Changes made by the clients in the pre-filled pages shall not constitute an update of their existing
        account information with BPI/BPI Family. If you wish to update your information, head to the nearest BPI/BPI
        Family branch.
      </div>
    </Wrapper>
  );
};

export default Footer;
