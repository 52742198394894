import React from 'react';
import Scrollspy from 'react-scrollspy';
import { Sidebar } from './index.style';

const S = () => (
  <Sidebar>
    <div className="scroll-wrapper">
      <Scrollspy
        items={['personal-section', 'work-section', 'profile-section', 'disclosures-section', 'fatca-section']}
        currentClassName="active"
        style={{ padding: 0, margin: 0 }}
        offset={-300}
      >
        <a href="#personal-section" className="link">
          Personal Information
        </a>
        <a href="#work-section" className="link">
          Work / Business Information
        </a>
        <a href="#profile-section" className="link">
          Investment Profile & Financial Information
        </a>
        <a href="#disclosures-section" className="link">
          Disclosures
        </a>
        <a href="#fatca-section" className="link">
          FATCA
        </a>
      </Scrollspy>
    </div>
  </Sidebar>
);

export default S;
