import { types } from 'mobx-state-tree';

const WorkBusinessInformation = types
  .model({
    employmentStatus: types.maybe(types.string),
    employmentStatusOthers: types.maybe(types.string),
    rank: types.maybe(types.string),
    rankOthers: types.maybe(types.string),
    position: '',
    natureOfBusiness: types.maybe(types.string),
    natureOfBusinessOthers: types.maybe(types.string),
    nameOfEmployerOrBusiness: '',
    businessAddress1: '',
    businessAddress2: '',
    businessAddress3: '',
    businessAddressZipCode: '',
    officePhoneNumber: '',
  })
  .actions(self => ({
    setEmploymentStatus(v: string): void {
      self.employmentStatus = v;

      if (self.employmentStatus != 'others') {
        self.employmentStatusOthers = '';
      }

      if (
        self.employmentStatus === 'student' ||
        self.employmentStatus === 'retired' ||
        self.employmentStatus === 'housewife' ||
        self.employmentStatus === 'unemployed'
      ) {
        this.setRank('others');
        this.setRankOthers('n/a');
        this.setPosition('n/a');
        this.setNatureOfBusiness('others');
        this.setNatureOfBusinessOthers('n/a');
        this.setNameOfEmployerOrBusiness('n/a');
        this.setBusinessAddress1('n/a');
        this.setBusinessAddress2('n/a');
        this.setBusinessAddress3('n/a');
        this.setBusinessAddressZipCode('n/a');
        this.setOfficePhoneNumber('n/a');
      } else if (self.rankOthers == 'n/a') {
        this.setRank(undefined);
        this.setRankOthers('');
        this.setPosition('');
        this.setNatureOfBusiness(undefined);
        this.setNatureOfBusinessOthers('');
        this.setNameOfEmployerOrBusiness('');
        this.setBusinessAddress1('');
        this.setBusinessAddress2('');
        this.setBusinessAddress3('');
        this.setBusinessAddressZipCode('');
        this.setOfficePhoneNumber('');
      }
    },
    setEmploymentStatusOthers(v: string): void {
      self.employmentStatusOthers = v;
    },
    setRank(v: string | undefined): void {
      self.rank = v;

      if (self.rank != 'others') {
        self.rankOthers = '';
      }
    },
    setRankOthers(v: string): void {
      self.rankOthers = v;
    },
    setPosition(v: string): void {
      self.position = v;
    },
    setNatureOfBusiness(v: string | undefined): void {
      self.natureOfBusiness = v;

      if (self.natureOfBusiness != 'others') {
        self.natureOfBusinessOthers = '';
      }
    },
    setNatureOfBusinessOthers(v: string): void {
      self.natureOfBusinessOthers = v;
    },
    setNameOfEmployerOrBusiness(v: string): void {
      self.nameOfEmployerOrBusiness = v;
    },
    setBusinessAddress1(v: string): void {
      self.businessAddress1 = v;
    },
    setBusinessAddress2(v: string): void {
      self.businessAddress2 = v;
    },
    setBusinessAddress3(v: string): void {
      self.businessAddress3 = v;
    },
    setBusinessAddressZipCode(v: string): void {
      self.businessAddressZipCode = v;
    },
    setOfficePhoneNumber(v: string): void {
      self.officePhoneNumber = v;
    },
  }));

export default WorkBusinessInformation;
