import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import countriesList from 'country-list';
import { Input, DatePicker, Checkbox, Select } from 'antd';
import PropTypes from 'prop-types';
import { references } from '@project/common';

import { AccountholderType } from '../../store/Accountholder';
import { FormSection, FormRow, RequiredFlag } from '../../components';
import { useMst } from '../../store/RootStore';

interface ComponentProps {
  errors: any;
  accountholder: AccountholderType;
  currentAccountholderIndex: number;
  validateInput: (path: string) => void;
}

// const countries = countriesList.getCodeList();
const countries = references.countries;
const nationalities = references.nationalities;

const countriesOptions = Object.keys(countries).map(code => {
  return (
    <Select.Option key={code} value={code}>
      {countries[code]}
    </Select.Option>
  );
});
const nationalitiesOptions = Object.keys(nationalities).map(code => {
  return (
    <Select.Option key={code} value={code}>
      {nationalities[code]}
    </Select.Option>
  );
});
const PersonalInformation: React.FC<ComponentProps> = observer(
  ({ errors, currentAccountholderIndex, accountholder, validateInput }) => {
    const store = useMst();

    useEffect(() => {
      if (store.applicationDetails.ownership === 'in_trust_for' && currentAccountholderIndex === 1) {
        accountholder.personal.setTin('000000000');
      }
    }, [currentAccountholderIndex]);

    return (
      <FormSection id="personal-section">
        <p className="title">Personal Details</p>
        <span className="description">
          All fields with <RequiredFlag /> are mandatory
        </span>
        <FormRow>
          <label className="form-title">Full Name</label>
          <div className={errors['personal.firstName'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="firstName"
              value={accountholder.personal.firstName}
              onChange={(e): void => accountholder.personal.setFirstName(e.target.value)}
              className="input"
              onBlur={(): void => validateInput('personal.firstName')}
              placeholder="First Name"
            />
            <RequiredFlag />
          </div>
          {errors['personal.firstName'] ? <span className="error-message">{errors['personal.firstName']}</span> : null}

          <div className={errors['personal.middleName'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="middleName"
              value={accountholder.personal.middleName}
              onChange={e => accountholder.personal.setMiddleName(e.target.value)}
              className="input"
              onBlur={() => validateInput('personal.middleName')}
              placeholder="Middle Name"
            />
            <RequiredFlag />
          </div>
          {errors['personal.middleName'] ? (
            <span className="error-message">{errors['personal.middleName']}</span>
          ) : null}

          <div className={errors['personal.lastName'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="lastName"
              value={accountholder.personal.lastName}
              onChange={e => accountholder.personal.setLastName(e.target.value)}
              className="input"
              onBlur={() => validateInput('personal.lastName')}
              placeholder="Last Name"
            />
            <RequiredFlag />
          </div>
          {errors['personal.lastName'] ? <span className="error-message">{errors['personal.lastName']}</span> : null}

          <div className={errors['personal.suffix'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Select
              showSearch
              value={accountholder.personal.suffix}
              onBlur={(): void => {
                validateInput('personal.suffix');
              }}
              placeholder="Suffix"
              optionFilterProp="children"
              onChange={(value: string): void => {
                accountholder.personal.setSuffix(value);
                validateInput('personal.suffixOthers');
              }}
            >
              {Object.keys(references.suffixes).map(key => (
                <Select.Option key={key} value={key}>
                  {references.suffixes[key]}
                </Select.Option>
              ))}
            </Select>
            <RequiredFlag />
          </div>
          {errors['personal.suffix'] ? <span className="error-message">{errors['personal.suffix']}</span> : null}

          <div className={errors['personal.otherSuffix'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="otherSuffix"
              value={accountholder.personal.suffixOthers}
              onChange={e => accountholder.personal.setSuffixOthers(e.target.value)}
              disabled={accountholder.personal.suffix !== 'others'}
              className="input"
              placeholder="Other suffix"
              onBlur={() => validateInput('personal.suffixOthers')}
            />
            {accountholder.personal.suffix === 'others' ? <RequiredFlag /> : null}
          </div>
          {errors['personal.suffixOthers'] ? (
            <span className="error-message">{errors['personal.suffixOthers']}</span>
          ) : null}
        </FormRow>
        <FormRow>
          <label className="form-title">Date of Birth</label>
          <div className={errors['personal.dateOfBirth'] ? 'controls --inline has-error' : 'controls --inline'}>
            <DatePicker
              placeholder="Date of birth"
              value={accountholder.personal.dateOfBirthMoment}
              onChange={(m, v) => accountholder.personal.setDateOfBirth(v)}
              onBlur={() => {
                validateInput('personal.dateOfBirth');
              }}
            />
            <RequiredFlag />
          </div>
          {errors['personal.dateOfBirth'] ? (
            <span className="error-message">{errors['personal.dateOfBirth']}</span>
          ) : null}

          <label className="form-title">Gender</label>
          <div className={errors['personal.gender'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Select
              showSearch
              value={accountholder.personal.gender}
              placeholder="Gender"
              optionFilterProp="children"
              onBlur={() => validateInput('personal.gender')}
              onChange={(value: string) => accountholder.personal.setGender(value)}
            >
              <Select.Option value="Male">Male</Select.Option>
              <Select.Option value="Female">Female</Select.Option>
            </Select>
            <RequiredFlag />
          </div>
          {errors['personal.gender'] ? <span className="error-message">{errors['personal.gender']}</span> : null}
        </FormRow>

        <FormRow>
          <label className="form-title">Place of birth</label>
          <div className={errors['personal.birthCountry'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Select
              showSearch
              placeholder="Country"
              optionFilterProp="children"
              value={accountholder.personal.birthCountry}
              onChange={(value: string) => accountholder.personal.setBirthCountry(value)}
              onBlur={() => validateInput('personal.birthCountry')}
            >
              {countriesOptions}
            </Select>
            <RequiredFlag />
          </div>
          {errors['personal.birthCountry'] ? (
            <span className="error-message">{errors['personal.birthCountry']}</span>
          ) : null}

          <div className={errors['personal.birthCity'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="birthCity"
              value={accountholder.personal.birthCity}
              onChange={e => accountholder.personal.setBirthCity(e.target.value)}
              className="input"
              placeholder="City"
              onBlur={() => validateInput('personal.birthCity')}
            />
            <RequiredFlag />
          </div>
          {errors['personal.birthCity'] ? <span className="error-message">{errors['personal.birthCity']}</span> : null}
        </FormRow>

        <FormRow>
          <label className="form-title">Nationality</label>
          <div className={errors['personal.nationality'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Select
              showSearch
              placeholder="Nationality"
              optionFilterProp="children"
              value={accountholder.personal.nationality}
              onChange={(value: string) => {
                accountholder.personal.setNationality(value);
                validateInput('personal.nationalityOthers');

                if (value === 'United States of America (USA)') {
                  accountholder.fatca.setIsUSPerson(true);
                } else {
                  accountholder.fatca.setIsUSPerson(false);
                }
              }}
              onBlur={() => {
                validateInput('personal.nationality');
              }}
            >
              <Select.Option value="others">Others</Select.Option>
              {nationalitiesOptions}
            </Select>
            <RequiredFlag />
          </div>
          {errors['personal.nationality'] ? (
            <span className="error-message">{errors['personal.nationality']}</span>
          ) : null}

          <div className={errors['personal.nationalityOthers'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="nationalityOthers"
              value={accountholder.personal.nationalityOthers}
              disabled={accountholder.personal.nationality !== 'others'}
              onChange={e => {
                accountholder.personal.setNationalityOthers(e.target.value);
                validateInput('personal.nationalityOthers');
              }}
              className="input"
              placeholder="Nationality others"
              onBlur={() => validateInput('personal.nationalityOthers')}
            />
            {accountholder.personal.nationality === 'others' ? <RequiredFlag /> : null}
          </div>
          {errors['personal.nationalityOthers'] ? (
            <span className="error-message">{errors['personal.nationalityOthers']}</span>
          ) : null}
        </FormRow>

        <FormRow>
          <label className="form-title">Citizenship</label>
          <div className={errors['personal.citizenship'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Select
              showSearch
              placeholder="Citizenship"
              optionFilterProp="children"
              value={accountholder.personal.citizenship}
              onChange={(value: string) => {
                accountholder.personal.setCitizenship(value);
                validateInput('personal.citizenshipOthers');
              }}
              onBlur={() => validateInput('personal.citizenship')}
            >
              <Select.Option value="others">Others</Select.Option>
              {countriesOptions}
            </Select>
            <RequiredFlag />
          </div>
          {errors['personal.citizenship'] ? (
            <span className="error-message">{errors['personal.citizenship']}</span>
          ) : null}

          <div className={errors['personal.citizenshipOthers'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="citizenshipOthers"
              value={accountholder.personal.citizenshipOthers}
              disabled={accountholder.personal.citizenship !== 'others'}
              onChange={e => accountholder.personal.setCitizenshipOthers(e.target.value)}
              className="input"
              placeholder="Citizenship others"
              onBlur={() => validateInput('personal.citizenshipOthers')}
            />
            {accountholder.personal.citizenship === 'others' ? <RequiredFlag /> : null}
          </div>
          {errors['personal.citizenshipOthers'] ? (
            <span className="error-message">{errors['personal.citizenshipOthers']}</span>
          ) : null}
        </FormRow>

        <FormRow>
          <label className="form-title">Civil Status</label>
          <div className={errors['personal.civilStatus'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Civil Status"
              value={accountholder.personal.civilStatus}
              onChange={(value: string) => {
                accountholder.personal.setCivilStatus(value);
                validateInput('personal.civilStatus');
              }}
              onBlur={() => validateInput('personal.civilStatus')}
            >
              {Object.keys(references.civilStatuses).map(key => (
                <Select.Option key={key} value={key}>
                  {references.civilStatuses[key]}
                </Select.Option>
              ))}
            </Select>
            <RequiredFlag />
          </div>
          {errors['personal.civilStatus'] ? (
            <span className="error-message">{errors['personal.civilStatus']}</span>
          ) : null}

          <label className="form-title">Spouse Name</label>
          <div className={errors['personal.spouseName'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="spouseName"
              value={accountholder.personal.spouseName}
              disabled={accountholder.personal.civilStatus !== 'Married'}
              onChange={e => accountholder.personal.setSpouseName(e.target.value)}
              className="input"
              placeholder="Spouse's name"
              onBlur={() => validateInput('personal.spouseName')}
            />
            {accountholder.personal.civilStatus === 'Married' ? <RequiredFlag /> : null}
          </div>
          {errors['personal.spouseName'] ? (
            <span className="error-message">{errors['personal.spouseName']}</span>
          ) : null}

          <label className="form-title">{"Mother's Maiden Name"}</label>
          <div className={errors['personal.motherMaidenName'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="motherMaidenName"
              value={accountholder.personal.motherMaidenName}
              onChange={e => accountholder.personal.setMotherMaidenName(e.target.value)}
              className="input"
              placeholder="Mother's maiden name"
              onBlur={() => validateInput('personal.motherMaidenName')}
            />
            <RequiredFlag />
          </div>
          {errors['personal.motherMaidenName'] ? (
            <span className="error-message">{errors['personal.motherMaidenName']}</span>
          ) : null}
        </FormRow>

        <FormRow>
          <label className="form-title">TIN</label>
          <div className={errors['personal.tin'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="tin"
              value={accountholder.personal.tin}
              onChange={e => accountholder.personal.setTin(e.target.value)}
              className="input"
              placeholder="TIN"
              onBlur={() => validateInput('personal.tin')}
            />
            {store.applicationDetails.ownership === 'in_trust_for' && currentAccountholderIndex === 1 ? null : (
              <RequiredFlag />
            )}
          </div>
          {errors['personal.tin'] ? <span className="error-message">{errors['personal.tin']}</span> : null}
        </FormRow>

        <FormRow>
          <label className="form-title">SSS/GSIS</label>
          <div className={errors['personal.sssGSISTag'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Select
              showSearch
              placeholder="SSS/GSIS Tag"
              value={accountholder.personal.sssGSISTag}
              onChange={(value: string) => accountholder.personal.setSSSGSISTag(value)}
              onBlur={() => validateInput('personal.sssGSISTag')}
            >
              <Select.Option value="sss">SSS</Select.Option>
              <Select.Option value="gsis">GSIS</Select.Option>
            </Select>
            {store.applicationDetails.ownership === 'in_trust_for' && currentAccountholderIndex === 1 ? null : (
              <RequiredFlag />
            )}
          </div>
          {errors['personal.sssGSISTag'] ? (
            <span className="error-message">{errors['personal.sssGSISTag']}</span>
          ) : null}

          <div className={errors['personal.sssGSISNo'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="sssGSISNo"
              value={accountholder.personal.sssGSISNo}
              onChange={e => accountholder.personal.setSSSGSISNo(e.target.value)}
              className="input"
              placeholder="SSS/GSIS No"
              onBlur={() => validateInput('personal.sssGSISNo')}
            />
            {store.applicationDetails.ownership === 'in_trust_for' && currentAccountholderIndex === 1 ? null : (
              <RequiredFlag />
            )}
          </div>
          {errors['personal.sssGSISNo'] ? <span className="error-message">{errors['personal.sssGSISNo']}</span> : null}
        </FormRow>
        <FormRow>
          <label className="form-title">ID</label>
          <div className={errors['personal.idType1'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Select
              showSearch
              value={accountholder.personal.idType1}
              onBlur={(): void => validateInput('personal.idType1')}
              placeholder="First ID: Type"
              optionFilterProp="children"
              onChange={(value: string): void => accountholder.personal.setIdType1(value)}
            >
              {Object.keys(references.idTypes).map(key => (
                <Select.Option key={key} value={key}>
                  {references.idTypes[key]}
                </Select.Option>
              ))}
            </Select>
            <RequiredFlag />
          </div>
          {errors['personal.idType1'] ? <span className="error-message">{errors['personal.idType1']}</span> : null}

          <div className={errors['personal.idNumber1'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="idNumber1"
              value={accountholder.personal.idNumber1}
              onChange={e => accountholder.personal.setIdNumber1(e.target.value)}
              className="input"
              placeholder="First ID: Number"
              onBlur={() => validateInput('personal.idNumber1')}
            />
            <RequiredFlag />
          </div>
          {errors['personal.idNumber1'] ? <span className="error-message">{errors['personal.idNumber1']}</span> : null}
        </FormRow>

        <FormRow>
          <label className="form-title">
            Present Address <RequiredFlag />
          </label>
          <div className={errors['personal.presentAddress1'] ? 'controls has-error' : 'controls'}>
            <Input
              name="presentAddress1"
              value={accountholder.personal.presentAddress1}
              onChange={e => accountholder.personal.setPresentAddress1(e.target.value)}
              className="input"
              placeholder="Unit, Floor, House No./Building, Street"
              onBlur={() => {
                validateInput('personal.presentAddress1');
                if (accountholder.personal.permanentAddressCheck) {
                  validateInput('personal.permanentAddress1');
                }
              }}
            />
            {errors['personal.presentAddress1'] ? (
              <span className="error-message">{errors['personal.presentAddress1']}</span>
            ) : null}
          </div>
          <div className={errors['personal.presentAddress2'] ? 'controls has-error' : 'controls'}>
            <Input
              name="presentAddress2"
              value={accountholder.personal.presentAddress2}
              onChange={e => accountholder.personal.setPresentAddress2(e.target.value)}
              className="input"
              placeholder="Subdivision, Barangay, District"
              onBlur={() => {
                validateInput('personal.presentAddress2');
                if (accountholder.personal.permanentAddressCheck) {
                  validateInput('personal.permanentAddress2');
                }
              }}
            />
            {errors['personal.presentAddress2'] ? (
              <span className="error-message">{errors['personal.presentAddress2']}</span>
            ) : null}
          </div>
          <div className={errors['personal.presentAddress3'] ? 'controls has-error' : 'controls'}>
            <Input
              name="presentAddress3"
              value={accountholder.personal.presentAddress3}
              onChange={e => accountholder.personal.setPresentAddress3(e.target.value)}
              className="input"
              placeholder="City / Province, Region, Country"
              onBlur={() => {
                validateInput('personal.presentAddress3');
                if (accountholder.personal.permanentAddressCheck) {
                  validateInput('personal.permanentAddress3');
                }
              }}
            />
            {errors['personal.presentAddress3'] ? (
              <span className="error-message">{errors['personal.presentAddress3']}</span>
            ) : null}
          </div>
          <div className={errors['personal.presentAddressZipCode'] ? 'controls has-error' : 'controls'}>
            <Input
              name="presentAddressZipCode"
              value={accountholder.personal.presentAddressZipCode}
              onChange={e => accountholder.personal.setPresentAddressZipCode(e.target.value)}
              className="input"
              placeholder="Present Address Zip Code"
              onBlur={() => {
                validateInput('personal.presentAddressZipCode');
                if (accountholder.personal.permanentAddressCheck) {
                  validateInput('personal.permanentAddressZipCode');
                }
              }}
            />
            {errors['personal.presentAddressZipCode'] ? (
              <span className="error-message">{errors['personal.presentAddressZipCode']}</span>
            ) : null}
          </div>
        </FormRow>
        <FormRow>
          <div className="controls">
            <Checkbox
              checked={accountholder.personal.permanentAddressCheck}
              onChange={e => {
                accountholder.personal.setPermanentAddressCheck(e.target.checked);
                validateInput('personal.permanentAddress1');
                validateInput('personal.permanentAddress2');
                validateInput('personal.permanentAddress3');
                validateInput('personal.permanentAddressZipCode');
              }}
            >
              Same as present address?
            </Checkbox>
          </div>
          <label className="form-title">
            Permanent Address <RequiredFlag />
          </label>
          <div className={errors['personal.permanentAddress1'] ? 'controls has-error' : 'controls'}>
            <Input
              name="permanentAddress1"
              value={accountholder.personal.permanentAddress1}
              disabled={accountholder.personal.permanentAddressCheck}
              onChange={e => accountholder.personal.setPermanentAddress1(e.target.value)}
              className="input"
              placeholder=" Unit, Floor, House No./Building, Street"
              onBlur={() => validateInput('personal.permanentAddress1')}
            />
            {errors['personal.permanentAddress1'] ? (
              <span className="error-message">{errors['personal.permanentAddress1']}</span>
            ) : null}
          </div>
          <div className={errors['personal.permanentAddress2'] ? 'controls has-error' : 'controls'}>
            <Input
              name="permanentAddress2"
              value={accountholder.personal.permanentAddress2}
              disabled={accountholder.personal.permanentAddressCheck}
              onChange={e => accountholder.personal.setPermanentAddress2(e.target.value)}
              className="input"
              placeholder="Subdivision, Barangay, District"
              onBlur={() => validateInput('personal.permanentAddress2')}
            />
            {errors['personal.permanentAddress2'] ? (
              <span className="error-message">{errors['personal.permanentAddress2']}</span>
            ) : null}
          </div>
          <div className={errors['personal.permanentAddress3'] ? 'controls has-error' : 'controls'}>
            <Input
              name="permanentAddress3"
              value={accountholder.personal.permanentAddress3}
              disabled={accountholder.personal.permanentAddressCheck}
              onChange={e => accountholder.personal.setPermanentAddress3(e.target.value)}
              className="input"
              placeholder="City / Province, Region, Country"
              onBlur={() => validateInput('personal.permanentAddress3')}
            />
            {errors['personal.permanentAddress3'] ? (
              <span className="error-message">{errors['personal.permanentAddress3']}</span>
            ) : null}
          </div>
          <div className={errors['personal.permanentAddressZipCode'] ? 'controls has-error' : 'controls'}>
            <Input
              name="permanentAddressZipCode"
              value={accountholder.personal.permanentAddressZipCode}
              disabled={accountholder.personal.permanentAddressCheck}
              onChange={e => accountholder.personal.setPermanentAddressZipCode(e.target.value)}
              className="input"
              placeholder="Permanent Address Zip Code"
              onBlur={() => validateInput('personal.permanentAddressZipCode')}
            />
            {errors['personal.permanentAddressZipCode'] ? (
              <span className="error-message">{errors['personal.permanentAddressZipCode']}</span>
            ) : null}
          </div>
        </FormRow>
        <FormRow>
          <label className="form-title">Contact details </label>
          <div className={errors['personal.homePhoneNumber'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="homePhoneNumber"
              value={accountholder.personal.homePhoneNumber}
              onChange={e => accountholder.personal.setHomePhoneNumber(e.target.value)}
              className="input"
              placeholder="Home phone number"
              onBlur={() => validateInput('personal.homePhoneNumber')}
            />
            <RequiredFlag />
          </div>
          {errors['personal.homePhoneNumber'] ? (
            <span className="error-message">{errors['personal.homePhoneNumber']}</span>
          ) : null}

          <div className={errors['personal.mobileNumber'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="mobileNumber"
              value={accountholder.personal.mobileNumber}
              onChange={e => accountholder.personal.setMobileNumber(e.target.value)}
              className="input"
              placeholder="Mobile number"
              onBlur={() => validateInput('personal.mobileNumber')}
            />
            <RequiredFlag />
          </div>
          {errors['personal.mobileNumber'] ? (
            <span className="error-message">{errors['personal.mobileNumber']}</span>
          ) : null}
        </FormRow>

        <FormRow>
          <div className={errors['personal.primaryEmailAddress'] ? 'controls --inline has-error' : 'controls --inline'}>
            <Input
              name="primaryEmailAddress"
              value={accountholder.personal.primaryEmailAddress}
              onChange={e => accountholder.personal.setPrimaryEmailAddress(e.target.value)}
              className="input"
              placeholder="Primary email address"
              onBlur={() => validateInput('personal.primaryEmailAddress')}
            />
            <RequiredFlag />
          </div>
          {errors['personal.primaryEmailAddress'] ? (
            <span className="error-message">{errors['personal.primaryEmailAddress']}</span>
          ) : null}

          <div className="controls --inline">
            <Input
              name="mobileNumber"
              value={accountholder.personal.secondaryEmailAddress}
              onChange={e => accountholder.personal.setSecondaryEmailAddress(e.target.value)}
              className="input"
              placeholder="Secondary email address"
              onBlur={() => validateInput('personal.secondaryEmailAddress')}
            />
          </div>
          {errors['personal.secondaryEmailAddress'] ? (
            <span className="error-message">{errors['personal.secondaryEmailAddress']}</span>
          ) : null}
        </FormRow>
      </FormSection>
    );
  },
);

PersonalInformation.propTypes = {
  errors: PropTypes.any.isRequired,
  accountholder: PropTypes.any.isRequired,
  validateInput: PropTypes.func.isRequired,
};

export default PersonalInformation;
