import React from 'react';
import { observer } from 'mobx-react';
import { Input, Radio, Checkbox, Select } from 'antd';
import PropTypes from 'prop-types';
import { references } from '@project/common';

import { AccountholderType } from '../../store/Accountholder';
import { FormSection, FormRow, RequiredFlag } from '../../components';

interface ComponentProps {
  errors: any;
  accountholder: AccountholderType;
  validateInput: (path: string) => void;
}

const InvestmentProfileInformation: React.FC<ComponentProps> = observer(({ errors, accountholder, validateInput }) => {
  return (
    <FormSection id="profile-section">
      <p className="title">Investment Profile & Financial Information</p>
      <span className="description">
        All fields with <RequiredFlag /> are mandatory
      </span>
      <FormRow>
        <label className="form-title">Investment Objective</label>
        <div className={errors['investment.investmentObjective'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Select
            placeholder="Investment Objective"
            value={accountholder.investment.investmentObjective}
            onBlur={() => validateInput('investment.investmentObjective')}
            onChange={(value: string) => accountholder.investment.setInvestmentObjective(value)}
          >
            {Object.keys(references.investmentObjectives).map(key => (
              <Select.Option key={key} value={key}>
                {references.investmentObjectives[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['investment.investmentObjective'] ? (
          <span className="error-message">{errors['investment.investmentObjective']}</span>
        ) : null}
      </FormRow>
      <FormRow>
        <label className="form-title">Years of Exp in Equity Investment</label>
        <div
          className={
            errors['investment.yearsOfExperienceInEquityInvestment']
              ? 'controls --inline has-error'
              : 'controls --inline'
          }
        >
          <Select
            value={accountholder.investment.yearsOfExperienceInEquityInvestment}
            onBlur={() => validateInput('investment.yearsOfExperienceInEquityInvestment')}
            placeholder="Years of exp. in Equity Investment"
            onChange={(value: string) => accountholder.investment.setYearsOfExperienceInEquityInvestment(value)}
          >
            {Object.keys(references.yearsReferences).map(key => (
              <Select.Option key={key} value={key}>
                {references.yearsReferences[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['investment.yearsOfExperienceInEquityInvestment'] ? (
          <span className="error-message">{errors['investment.yearsOfExperienceInEquityInvestment']}</span>
        ) : null}
      </FormRow>

      <FormRow>
        <label className="form-title">Do you have accounts with other brokers / dealers?</label>
        <div
          className={
            errors['investment.hasAccountsWithOtherBroker'] ? 'controls --inline has-error' : 'controls --inline'
          }
        >
          <Radio.Group
            name="hasAccountsWithOtherBroker"
            value={accountholder.investment.hasAccountsWithOtherBroker}
            onChange={e => accountholder.investment.setHasAccountWithOtherBroker(e.target.value)}
          >
            <Radio value={true}>Yes</Radio>
            <Radio value={false}>No</Radio>
          </Radio.Group>
        </div>
        {errors['investment.hasAccountsWithOtherBroker'] ? (
          <span className="error-message">{errors['investment.hasAccountsWithOtherBroker']}</span>
        ) : null}

        <div
          className={
            errors['investment.accountsWithOtherBrokerName'] ? 'controls --inline has-error' : 'controls --inline'
          }
        >
          <Input
            name="position"
            value={accountholder.investment.accountsWithOtherBrokerName}
            onChange={e => accountholder.investment.setAccountWithOtherBrokerName(e.target.value)}
            className="input"
            placeholder="Name of broker / dealer"
            disabled={!accountholder.investment.hasAccountsWithOtherBroker}
            onBlur={() => validateInput('investment.accountsWithOtherBrokerName')}
          />
          {accountholder.investment.hasAccountsWithOtherBroker ? <RequiredFlag /> : null}
        </div>
        {errors['investment.accountsWithOtherBrokerName'] ? (
          <span className="error-message">{errors['investment.accountsWithOtherBrokerName']}</span>
        ) : null}
      </FormRow>

      <FormRow>
        <label className="form-title">Source of Funds</label>
        <div className={errors['investment.sourceOfFunds'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Select
            value={accountholder.investment.sourceOfFunds}
            onBlur={() => validateInput('investment.sourceOfFunds')}
            placeholder="Source of funds"
            onChange={(value: string) => {
              accountholder.investment.setSourceOfFunds(value);
              validateInput('investment.sourceOfFundsOthers');
            }}
          >
            {Object.keys(references.sourceOfFunds).map(key => (
              <Select.Option key={key} value={key}>
                {references.sourceOfFunds[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['investment.sourceOfFunds'] ? (
          <span className="error-message">{errors['investment.sourceOfFunds']}</span>
        ) : null}

        <div className={errors['investment.sourceOfFundsOthers'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Input
            name="position"
            value={accountholder.investment.sourceOfFundsOthers}
            onChange={e => accountholder.investment.setSourceOfFundsOthers(e.target.value)}
            className="input"
            placeholder="Source of funds (others)"
            disabled={accountholder.investment.sourceOfFunds !== 'others'}
            onBlur={() => validateInput('investment.sourceOfFundsOthers')}
          />
          {accountholder.investment.sourceOfFunds === 'others' ? <RequiredFlag /> : null}
        </div>
        {errors['investment.sourceOfFundsOthers'] ? (
          <span className="error-message">{errors['investment.sourceOfFundsOthers']}</span>
        ) : null}
      </FormRow>

      <FormRow>
        <label className="form-title">Annual Gross Income</label>
        <div className={errors['investment.annualGrossIncome'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Select
            placeholder="Annual Gross Income"
            value={accountholder.investment.annualGrossIncome}
            onBlur={() => validateInput('investment.annualGrossIncome')}
            onChange={(value: string) => accountholder.investment.setAnnualGrossIncome(value)}
          >
            {Object.keys(references.moneyReferences).map(key => (
              <Select.Option key={key} value={key}>
                {references.moneyReferences[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['investment.annualGrossIncome'] ? (
          <span className="error-message">{errors['investment.annualGrossIncome']}</span>
        ) : null}
      </FormRow>
      <FormRow>
        <label className="form-title">Assets</label>
        <div className={errors['investment.assets'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Select
            placeholder="Assets"
            value={accountholder.investment.assets}
            onBlur={() => validateInput('investment.assets')}
            onChange={(value: string) => accountholder.investment.setAssets(value)}
          >
            {Object.keys(references.moneyReferences).map(key => (
              <Select.Option key={key} value={key}>
                {references.moneyReferences[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['investment.assets'] ? <span className="error-message">{errors['investment.assets']}</span> : null}
      </FormRow>
      <FormRow>
        <label className="form-title">Net Worth</label>
        <div className={errors['investment.netWorth'] ? 'controls --inline has-error' : 'controls --inline'}>
          <Select
            placeholder="Net worth"
            value={accountholder.investment.netWorth}
            onBlur={() => validateInput('investment.netWorth')}
            onChange={(value: string) => accountholder.investment.setNetWorth(value)}
          >
            {Object.keys(references.moneyReferences).map(key => (
              <Select.Option key={key} value={key}>
                {references.moneyReferences[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['investment.netWorth'] ? <span className="error-message">{errors['investment.netWorth']}</span> : null}
      </FormRow>
      <FormRow>
        <label className="form-title">Investment Risk Appetite</label>
        <div
          className={errors['investment.investmentRiskAppetite'] ? 'controls --inline has-error' : 'controls --inline'}
        >
          <Select
            placeholder="Investment Risk Appetite"
            value={accountholder.investment.investmentRiskAppetite}
            onBlur={() => validateInput('investment.investmentRiskAppetite')}
            onChange={(value: string) => accountholder.investment.setInvestmentRiskAppetite(value)}
          >
            {Object.keys(references.investmentRiskAppetite).map(key => (
              <Select.Option key={key} value={key}>
                {references.investmentRiskAppetite[key]}
              </Select.Option>
            ))}
          </Select>
          <RequiredFlag />
        </div>
        {errors['investment.investmentRiskAppetite'] ? (
          <span className="error-message">{errors['investment.investmentRiskAppetite']}</span>
        ) : null}
      </FormRow>
    </FormSection>
  );
});

InvestmentProfileInformation.propTypes = {
  errors: PropTypes.any.isRequired,
  accountholder: PropTypes.any.isRequired,
  validateInput: PropTypes.func.isRequired,
};

export default InvestmentProfileInformation;
