import { types } from 'mobx-state-tree';

const Disclosures = types
  .model({
    isOfficerOrDirectorOfListedCompany: types.maybe(types.boolean),
    corporationName: '',
    position: '',
    isEmployeeOfRegisteredBroker: types.maybe(types.boolean),
    employeeBrokerName: '',
    isOfficerOrDirectorOfARegisteredBroker: types.maybe(types.boolean),
    officerBrokerName: '',
    hasRelativesInBPISecurities: types.maybe(types.boolean),
    bpiSecuritiesEmployeeName: '',
  })
  .actions(self => ({
    setIsOfficerOrDirectorOfListedCompany(v: boolean | undefined): void {
      self.isOfficerOrDirectorOfListedCompany = v;

      if (!v) {
        self.corporationName = '';
        self.position = '';
      }
    },
    setCorporationname(v: string): void {
      self.corporationName = v;
    },
    setPosition(v: string): void {
      self.position = v;
    },
    setIsEmployeeOfRegisteredBroker(v: boolean | undefined): void {
      self.isEmployeeOfRegisteredBroker = v;

      if (!v) {
        self.employeeBrokerName = '';
      }
    },
    setEmployeeBrokerName(v: string): void {
      self.employeeBrokerName = v;
    },
    setIsOfficerOrDirectorOfARegisteredBroker(v: boolean | undefined): void {
      self.isOfficerOrDirectorOfARegisteredBroker = v;

      if (!v) {
        self.officerBrokerName = '';
      }
    },
    setOfficerBrokerName(v: string): void {
      self.officerBrokerName = v;
    },
    setHasRelativesInBPISecurities(v: boolean): void {
      self.hasRelativesInBPISecurities = v;
      if (!v) {
        self.bpiSecuritiesEmployeeName = '';
      }
    },
    setBPISecuritiesEmployeeName(v: string): void {
      self.bpiSecuritiesEmployeeName = v;
    },
  }));

export default Disclosures;
