import { types, Instance, applySnapshot } from 'mobx-state-tree';

const ApplicationDetails = types
  .model({
    currency: types.optional(types.string, 'peso'),
    ownership: types.optional(types.string, 'individual'),
    accountHolders: types.maybe(types.number),
    beneficiaries: types.maybe(types.number),
  })
  .actions(self => ({
    save(data: ApplicationDetailsType) {
      applySnapshot(self, data);
    },
    setBeneficiaries(beneficiaries: number) {
      self.beneficiaries = beneficiaries;
    },
  }));

export interface ApplicationDetailsType extends Instance<typeof ApplicationDetails> {} // eslint-disable-line

export default ApplicationDetails;
