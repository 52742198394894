import styled from 'styled-components';

const Main = styled.div`
  margin: 0px auto;
  margin-top: 120px;
  margin-bottom: 50px;
  padding: 0px 0px;
  width: 1024px;

  .font-weight-bold {
    font-weight: bold;
  }

  .heading-1 {
    font-size: 32px;
  }
  .heading-2 {
    font-size: 28px;
  }
  .heading-3 {
    font-size: 24px;
  }
  .heading-4 {
    font-size: 20px;
  }
  .heading-5 {
    font-size: 18px;
  }
  .heading-6 {
    font-size: 14px;
  }

  @media (max-width: 1024px) {
    padding: 0px 30px;
  }

  @media (max-width: 768px) {
    width: auto;
    padding: 0px 20px;

    .heading-1 {
      font-size: 28px;
    }
    .heading-2 {
      font-size: 24px;
    }
    .heading-3 {
      font-size: 20px;
    }
    .heading-4 {
      font-size: 18px;
    }
    .heading-5 {
      font-size: 14px;
    }
    .heading-6 {
      font-size: 12px;
    }
  }
`;

export default Main;
