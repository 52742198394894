import React, { useState, useEffect } from 'react';
import IdleTimer from 'react-idle-timer';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import queryString from 'query-string';

import { Provider, rootStore, useMst } from './store/RootStore';

import 'antd/dist/antd.css';

import Login from './pages/Login';
import TermsAndCondition from './pages/TermsAndCondition';
import ApplicationDetails from './pages/ApplicationDetails';
import AccountholderDetails from './pages/AccountholderDetails';
import TradeAccountDetails from './pages/TradeAccountDetails';
import ReviewApplication from './pages/ReviewApplication';
import ThankYouPage from './pages/ThankYouPage';
import LoadingPage from './pages/LoadingPage';

import AuthenticatedRoute from './hoc/AuthenticatedRoute';

import Main from './components/Main';
import Header from './components/Header';
import Footer from './components/Footer';
import { Modal, Button } from 'antd';

const App: React.FC = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);

  const [idleModalVisible, setIdleModalVisible] = useState(false);

  useEffect(() => {
    const parsed: { id?: string } = queryString.parse(location.search);

    if (parsed.id) {
      // if application id is valid
      // save it in store
      // else let route handle the rest
      rootStore.checkApplicationId(parsed.id).then(() => {
        rootStore.setApplicationId(parsed.id as string);
        setIsPageLoading(false);
      });
    } else {
      setIsPageLoading(false);
    }
  }, []);

  if (isPageLoading) {
    return <LoadingPage />;
  }

  const handleTimeout = async (): Promise<void> => {
    await rootStore.saveErrorResponse('timeout');
    setTimeout(() => {
      window.location.href = '/';
    }, 300);
  };

  let idleInterval: number | null = null;

  return (
    <Provider value={rootStore}>
      <Router>
        <IdleTimer
          timeout={900000}
          onIdle={(): void => {
            setIdleModalVisible(true);
            idleInterval = setInterval(async () => {
              await handleTimeout();
            }, 30000);
          }}
        />
        <Modal visible={idleModalVisible} title="Session Timeout" footer={null}>
          <p>Do you want to extend your session?</p>
          <div className="d-flex">
            <Button onClick={handleTimeout} type="link" size="large" className="mr-3">
              No
            </Button>
            <Button
              type="primary"
              size="large"
              onClick={() => {
                setIdleModalVisible(false);
                if (idleInterval) {
                  clearInterval(idleInterval);
                }
              }}
            >
              Yes, extend my session
            </Button>
          </div>
        </Modal>
        <div className="app">
          <Header />
          <Main>
            <Switch>
              <Route exact path="/">
                <Login />
              </Route>
              <AuthenticatedRoute exact path="/terms">
                <TermsAndCondition />
              </AuthenticatedRoute>
              <AuthenticatedRoute exact path="/application_details">
                <ApplicationDetails path="/application_details" />
              </AuthenticatedRoute>
              <Route exact path="/accountholder_details">
                <AccountholderDetails />
              </Route>
              <Route exact path="/trade_account_details">
                <TradeAccountDetails />
              </Route>
              <Route exact path="/review_accountholders">
                <ReviewApplication />
              </Route>
              <Route exact path="/thank_you">
                <ThankYouPage />
              </Route>
            </Switch>
          </Main>
          <Footer />
        </div>
      </Router>
    </Provider>
  );
};

export default App;
