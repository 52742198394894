import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from 'antd';
import { IoIosMail } from 'react-icons/io';
import { FaPhone } from 'react-icons/fa';

import { useMst } from '../store/RootStore';
import { AccountholderType } from '../store/Accountholder';
import config from '../config';

const Wrapper = styled.div`
  .title {
    font-size: 18px;
    margin-bottom: 30px;
  }
  p {
    margin-bottom: 30px;
  }
`;

const ThankYouPage: React.FC = () => {
  const store = useMst();

  const [applicationNumber, setApplicationNumber] = useState('');

  useEffect(() => {
    store.ui.setStep(3);

    store.getApplicationNumber().then(res => {
      setApplicationNumber(res.data.applicationNumber);
    });

    setTimeout(() => {
      const link = document.createElement('a');
      link.download = name;
      link.target = '_blank';
      link.href = config.termsPDFUrl;
      link.click();
    }, 500);
  }, []);

  const USPeople = store.accountholders.filter((accountholder: AccountholderType) => {
    return accountholder.fatca.isUSPerson;
  });

  const USPeopleNames = USPeople.map(people => `${people.personal.firstName} ${people.personal.lastName}`).join(', ');

  return (
    <Wrapper>
      <h2 className="title">THANK YOU FOR APPLYING FOR A BPI TRADE ONLINE ACCOUNT</h2>
      <p>
        Your application number is: <b>{applicationNumber}</b>
      </p>
      <p>
        Kindly print and sign three times on the space provided on your accomplished Account Opening Form. Send the
        signed form and a photo of your valid ID to bpitradeapplications@bpi.com.ph.
      </p>
      <p>
        A BPI Trade representative will reach out to you to schedule a video call. For your video appointment, please
        prepare your submitted ID and specimen signatures.
      </p>
      <p>
        For questions or concerns regarding your applications, you may get in touch with us through the following
        channels:
      </p>
      <div>
        <IoIosMail style={{ marginRight: 10 }} /> bpitradeapplications@bpi.com.ph{' '}
      </div>
      <div>
        <FaPhone style={{ marginRight: 10 }} />
        (02) 8246 5555
      </div>
    </Wrapper>
  );
};

export default ThankYouPage;
