import { types, Instance, applySnapshot } from 'mobx-state-tree';

const TermsAndConditions = types
  .model({
    status: types.optional(types.string, ''),
    errormessage: types.optional(types.string, ''),
    versionnumber: types.optional(types.string, ''),
    url: types.optional(types.string, ''),
    filename: types.optional(types.string, ''),
  })
  .actions(self => ({
    save(data: TermsAndConditionsType) {
      applySnapshot(self, data);
    },
  }));

export interface TermsAndConditionsType extends Instance<typeof TermsAndConditions> {} // eslint-disable-line

export default TermsAndConditions;
